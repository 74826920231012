<template>
  <div class="visitor-item">
    <header
      class="visitor-item__title"
      :style="{ 'justify-content': lastetReply ? 'space-between' : 'flex-end' }"
    >
      <template v-if="lastetReply">
        <div class="item__service">{{ lastetReply.userName }}</div>
        <div class="item-time">{{ lastetReply.createTime | formatTime }}，回复了您的留言</div>
      </template>
      <!-- status 为 2 是历史留言，这里操作是删除 -->
      <slot name="button">
        <div class="item-button" @click="handleComment">{{ data.status == 9 ? '删除' : '关闭' }}</div>
      </slot>
    </header>
    <!-- 查看更多 -->
    <section class="visitor-item__content" v-if="data.leaveDetails">
      <section
        class="visitor-item__list"
        :class="{ 'list-all': listAll, 'has-more': hasMore }"
        ref="messageItem"
      >
        <message-item v-for="msg in listData" :key="msg.commentsId + '' + msg.id" :data="msg" />
      </section>

      <!-- 内容大于3条显示更多 -->
      <!-- <div
        class="list-folder"
        :class="{ 'is-opened':  listOpened}"
        v-if="!listAll && data.leaveDetails && data.leaveDetails.length > maxShow"
        @click="() => listOpened = !listOpened"
      >
        {{listOpened ? '收起' : '查看更多'}}
        <van-icon name="arrow-down" />
      </div>-->
      <div class="list-folder" v-if="!listAll && hasMore" @click="replayComment">
        查看更多
        <van-icon name="arrow-down" />
      </div>

      <van-button
        v-if="action"
        block
        size="small"
        color="#00ae7e"
        @click="replayComment"
        loading-type="spinner"
        loading-size="12px"
        style="margin-top: 15px;"
      >回复留言</van-button>
    </section>
  </div>
</template>

<script>
import { Button, Icon } from 'vant'
import MessageItem from './MessageItem.vue'
import messageHandler from '@/views/VisitorBook/mixins/messageHandler'
import DateUtils from '@/utils/DateUtils'

export default {
  name: 'VisitorItem',
  mixins: [messageHandler],
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    action: {
      type: Boolean,
      default: true
    },
    listAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      listOpened: false,
      maxShow: 3,
      hasMore: true
    }
  },
  computed: {
    // 计算得出当前展示的列表数据
    listData () {
      const leaveDetails = this.data.leaveDetails || []
      if (this.isListOpened || this.listAll) {
        return leaveDetails
      } else {
        return leaveDetails.slice(0, this.maxShow)
      }
    },
    lastetReply () {
      const leaveDetails = this.data.leaveDetails || []
      return leaveDetails.find(item => item.stype === 2)
    }
    // 判断列表是不是显示全部数据
    // isListOpened () {
    //   const leaveDetails = this.data.leaveDetails
    //   if (this.listAll) {
    //     return true
    //   } else if (
    //     leaveDetails &&
    //     leaveDetails.length > this.maxShow &&
    //     !this.listOpened
    //   ) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }
  },
  filters: {
    formatTime (input) {
      return DateUtils.getSimpleDate(input)
    }
  },
  components: {
    MessageItem,
    [Button.name]: Button,
    [Icon.name]: Icon
  },
  mounted () {
    this.getContent()
    this.$watch(
      () => {
        return this.data
      },
      () => {
        this.$nextTick(this.getContent)
      }
    )
  },
  methods: {
    /**
    根据内容的高度判断是不是显示更多按钮 */
    getContent () {
      if (this.listAll) {
        this.hasMore = false
      } else if (
        this.data.leaveDetails &&
        this.data.leaveDetails.length > this.maxShow
      ) {
        this.hasMore = true
      } else {
        // 如果有图片还要监听图片加载完成的事件
        // 对每个里面的图片增加事件
        const images = this.listData.reduce((result, item) => {
          item.leaveFiles.map(image => {
            result.push(
              new Promise((resolve, reject) => {
                const loader = new Image()
                loader.addEventListener('load', resolve)
                loader.addEventListener('error', reject)
                loader.setAttribute('src', image.fileUrl)
              })
            )
          })
          return result
        }, [])
        if (images.length < 1) {
          this.hasMore = this.$refs.messageItem.offsetHeight >= 260
        } else {
          Promise.all(images).then(() => {
            // console.log(this.$refs.messageItem)
            this.hasMore = this.$refs.messageItem.offsetHeight >= 260
          })
        }
        // this.hasMore = this.$refs.messageItem.offsetHeight >= 260
      }
    },
    /**
     * 回复留言
     */
    replayComment () {
      this.$router.push({
        name: this.data.status === 9 ? 'VisitorView' : 'VisitorReply',
        params: {
          id: this.data.id
        }
      })
    },
    /**
     * 处理留言
     */
    handleComment () {
      if (this.data.status === 9) {
        // 历史留言进行删除
        this.deleteComment(this.data.id)
      } else {
        // 留言关闭
        this.colseComment(this.data.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.visitor-item {
  background-color: #ededed;
  margin-bottom: 20px;
  padding: 0 10px 42px 10px;
  &__title {
    display: flex;
    align-items: flex-end;
    height: 40px;
    line-height: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #fff;
    .item__service {
      font-size: 26px;
      font-weight: bold;
      color: #201e1e;
    }
    .item-time {
      font-size: 22px;
      color: #909399;
      margin-left: 20px;
      flex: 2;
    }
  }
  .list-folder {
    position: relative;
    z-index: 10;
    text-align: center;
    font-size: 22px;
    color: #00ae7e;
    margin-top: 20px;
    &:after {
      content: '';
      display: inline-block;
    }
    .van-icon {
      transition: all ease 0.2s;
    }
    &.is-opened .van-icon {
      transform: rotateZ(180deg);
    }
  }
  &__list {
    position: relative;
    z-index: 1;
    max-height: 260Px;
    overflow: hidden;
    &.list-all {
      max-height: unset;
    }
    &.has-more:after {
      position: absolute;
      z-index: 1;
      bottom: 0;
      content: '';
      display: block;
      width: 100%;
      height: 50px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), 50%, #efefef);
    }
  }
}
::v-deep {
  .item-button {
    font-size: 28px;
    color: #00ae7e;
    cursor: pointer;
  }
}
</style>
