<template>
  <div class="message-item__wrapper">
    <div class="message-item" :class="'type-' + type">
      <div v-if="type === 'robot'" class="message-item__avator">
        <van-image fit="cover" :src="robot" />
      </div>
      <div class="message-item__message">
        <!-- 内容的展示形式：先图后文字 -->
        <template v-if="data.leaveFiles">
          <van-image
            fit="contain"
            v-for="(item) in data.leaveFiles"
            :key="data.commentsId + '' + item.id"
            :src="item.fileUrl"
          ></van-image>
        </template>
        <p class="message-item__txt" v-if="data.comments">{{data.comments}}</p>
      </div>
      <div v-if="type === 'user'" class="message-item__avator">
        <van-image fit="cover" :src="user" />
      </div>
    </div>

    <div class="message-item__time">{{ data.createTime }}</div>
  </div>
</template>

<script>
import { Image } from 'vant'

export default {
  name: 'MessageItem',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      user: require('@/assets/icons/avatat_default.png')
    }
  },
  computed: {
    robot () {
      return this.$store.state.robotConfig.icon
    },
    type () {
      if (this.data.stype === 2) {
        return 'robot'
      } else {
        return 'user'
      }
    }
  },
  components: {
    [Image.name]: Image
  }
}
</script>

<style lang="scss" scoped>
// 头像的尺寸
$avatarSize: 60px;
.message-item__wrapper {
  margin-top: 25px;
  // margin-bottom: 25px;
}
.message-item {
  display: flex;
  max-width: calc(100% - #{$avatarSize});
  &__avator {
    min-width: $avatarSize;
    width: $avatarSize;
    height: $avatarSize;
    border-radius: 6px;
    &:not(:first-child) {
      margin-left: 18px;
    }
    &:not(:last-child) {
      margin-right: 18px;
    }
  }
  &__message {
    position: relative;
    z-index: 1;
    background-color: #fff;
    border-radius: 10px;
    line-height: 1.5em;
    padding: 17px 25px 16px 20px;
    font-size: 24px;
    color: #201e1e;
    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      z-index: 1;
      top: 20px;
      border-top: 12px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 15px solid transparent;
    }
    &:empty:after {
      content: '未输入留言内容';
    }
  }
  &__txt {
    margin-bottom: 0;
    word-wrap: break-word;
    word-break: break-all;
    &:first-child {
      margin-top: 0;
    }
  }
  &__time {
    font-size: 20px;
    color: #909399;
    text-align: center;
    line-height: 1em;
    margin-top: 25px;
  }

  // 客服
  &.type-robot {
    justify-content: flex-start;
    .message-item__message:before {
      left: -24px;
      border-right-color: #fff;
    }
  }
  // 用户发送端
  &.type-user {
    justify-content: flex-end;
    margin-left: $avatarSize;
    .message-item__message {
      background-color: #cadbf3;
      &:before {
        right: -25px;
        border-left-color: #cadbf3;
      }
    }
  }
}
.van-image {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
